import React from 'react';
import styled from 'styled-components';
import ReactResizeDetector from 'react-resize-detector';
import Youtube from 'react-youtube';
import theme from '../theme';

const Video = ({videoId, className})=>{
    return(
        <ReactResizeDetector handleWidth>
        {({width})=> {
            if(!width){return <div />}
            var opts = {height: "174", width: "280"};
            if(width > theme.breakpoints.tablet){
                opts = {height: "390", width: "640"};
            }else if(width > theme.breakpoints.mobile){
                opts = {height: "220", width: "360"};
            }
            return (
                <Youtube
                    videoId={videoId}
                    opts={opts}
                    containerClassName={ className }
                /> 
            )
        }}
        </ReactResizeDetector>
    )
}

const StyledVideo = styled(Video)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default StyledVideo;