import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Carousel, { PreviewCarousel } from '../components/carousel';
import Button from '../components/button';
import TextCard from '../components/textCard';
import breakpoint from 'styled-components-breakpoint';
import Video from '../components/video';
import getYouTubeID from 'get-youtube-id';
import Link from '../components/betterLink';
import SEO from '../components/seo';
import HtmlReactParser from 'html-react-parser';
import ReactToString from '../helper/reactToString';

const CarouselWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px 20px 10px;
  ${breakpoint('tablet')`
    padding: 20px 120px 60px 120px;
  `}
`;

const QuoteButton = styled(Button)`
    display: block;
    margin: 0 auto 90px auto;
`;

const TextCardWrapper = styled.div`
    padding: 30px 0 60px 0;
`;

const VideoWrapper = styled.div`
    margin: 0 0 60px 0;
`;

export const ProductTemplate = ({
    preview,
    title,
    nameSplitPosition,
    description,
    carousel,
    video,
    thumbnail,
    url,
})=>{
    const videoIds = video.map(url=> getYouTubeID(url));
    return(
        <div>
            {
                !preview 
                && 
                <SEO 
                    title={title} 
                    description={ReactToString(description)} 
                    image={url+thumbnail.childImageSharp.fluid.src}
                />
            }
            <CarouselWrapper>
                {
                    preview ?
                        <PreviewCarousel images={carousel}/>
                    :
                        <Carousel fluids={carousel.map(node=>node.childImageSharp.fluid)}/>
                }
            </CarouselWrapper>
            <TextCardWrapper>
                <TextCard title={title} nameSplitPosition={nameSplitPosition} description={description}/>
            </TextCardWrapper>
            {
                preview ? 
                    <QuoteButton text="諮詢產品" /> 
                :
                    <Link to="/contact">
                        <QuoteButton text="諮詢產品" />
                    </Link>
            }
            {
                videoIds.map((vid,index)=>(
                    <VideoWrapper key={index}>
                        <Video videoId={vid}/>
                    </VideoWrapper>
                ))
            }
        </div>
    )
}

const Product = ({data})=>{
    const { title, nameSplitPosition, carousel, video, thumbnail } = data.markdownRemark.frontmatter;
    const description = HtmlReactParser(data.markdownRemark.html);
    const { url } = data.site.siteMetadata;
    return(
        <ProductTemplate 
            title={title}
            nameSplitPosition={nameSplitPosition}
            description={description}
            carousel={carousel}
            video={video}
            thumbnail={thumbnail}
            url={url}
        />
    )
}

export default Product;

export const query = graphql`
    query ProductByID($id : String!){
        site {
            siteMetadata {
                url
            }
        }
        markdownRemark(id: { eq: $id }){
            html
            frontmatter{
                title
                nameSplitPosition
                carousel{
                    childImageSharp{
                        fluid(maxWidth: 2048){
                            src
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                video
                thumbnail{
                    childImageSharp{
                        fluid(maxWidth: 400){
                            src
                        }
                    }
                }
            }
        }
    }
`;